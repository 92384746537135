













































































































































import Vue from "vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";

import City from "@/models/City";
import { mapActions, mapGetters, mapState } from "vuex";
import rules from "@/components/account/rules";
import { getAPI } from "@/api/axios-base";
import { notifyInfo } from "@/components/Notification";

export default Vue.extend({
  components: { MaDatePicker },
  name: "btn-update-helf-care",
  props: {
    patient: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      loadingCities: false,
      stateCities: [],
      state: "",
      rules: rules,
      fields: {
        ph: "", // ph info
        fax: "", // string fax
        hfacilityName: "", //
        hFacilityAddres: "", // addres
        hfacilityCityId: 0, //
        datesOfTR: "", // 22021-07-25T04:05:44.586Z
        reasonForD: "", //
      },
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
  },
  watch: {
    state: function (stateId: number): void {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.actGetCityByState(stateId).then((cities: City[]) => {
        this.loadingCities = false;
        (this.stateCities as any) = cities;
      });
    },
  },
  mounted() {
    this.actListStates();
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates", "actGetCityByState"]),
    resetForm() {
      if ((this as any).$refs.form) {
        (this as any).$refs.form.reset();
        (this as any).$refs.form.resetValidation();
      }
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    update() {
      this.loading = true;
      getAPI
        .post(`/patient/updateHelftCare/${this.patient.id}`, this.fields)
        .then(() => {
          this.loading = false;
          this.closeDialog();
          notifyInfo("The Health Care data have been updated successfully");
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
});
