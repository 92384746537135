






























































import { notifyError, notifySuccess } from "@/components/Notification";
import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "btn-remove-procedure",
  props: {
    procedure: Object,
  },
  data() {
    return {
      dialogConfirmRemove: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions("crmDashboardModule", ["actRemoveProcedureStatus"]),
    remove() {
      this.loading = true;

      this.actRemoveProcedureStatus({ id: this.procedure.id })
        .then(() => {
          this.dialogConfirmRemove = false;
          this.loading = false;
          notifySuccess(this.$t("notifyRemove"));
          this.$emit("removed");
        })
        .catch((error) => {
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
          this.dialogConfirmRemove = false;
          this.loading = false;
        });
    },
  },
});
