<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="d-flex align-center justify-center flex-column px-3">
    <v-container fluid>
      <div class="d-flex justify-end mx-4">
        <template v-if="!edit">
          <v-btn @click="toEdit()" class="mx-3 mb-2" small color="info"
            ><v-icon>mdi-pencil</v-icon> Edit</v-btn
          >
        </template>
        <template v-else>
          <v-btn
            @click="discardChanges"
            :disabled="loadingedit"
            class="mr-2 mb-2"
            small
            color="error"
            ><v-icon>mdi-cancel</v-icon> Cancel</v-btn
          >
          <v-btn
            :loading="loadingedit"
            :disabled="loadingedit"
            @click="saveData"
            class="mr-3 mb-2"
            small
            color="success"
            ><v-icon>mdi-content-save</v-icon> Save</v-btn
          >
        </template>
      </div>
      <v-row align="center" justify="center" class="mx-4">
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="patient.weight"
            outlined
            :readonly="!edit"
            label="Weight"
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" class="d-flex">
          <v-text-field
            v-model="patient.height"
            outlined
            :readonly="!edit"
            label="Height (ft)"
            dense
          ></v-text-field>
          <v-text-field
            v-model="patient.heightInches"
            outlined
            class="ml-1"
            :readonly="!edit"
            label="Height (inch)"
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <div class="d-flex">
            <div class="col-sm-6">
              <v-text-field
                v-model="bmi"
                readonly
                outlined
                label="BMI"
                dense
              ></v-text-field>
            </div>
            <div class="col-sm-6">
              <span :class="`text-body-1 ${colorWeight}--text`">{{
                typebmi
              }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <ma-date-picker
            past
            :disable="!edit"
            v-model="patient.birthDate"
            label="DOB"
            :editable="true"
          />
        </v-col>
      </v-row>
      <v-divider class="mx-4 texts"></v-divider>
      <v-row align="center" justify="center" class="mx-4">
        <v-col cols="12" sm="6">
          <template v-if="!edit">
            <v-text-field
              :readonly="!edit"
              v-model="procedureName"
              outlined
              label="Procedure of interest"
              dense
            ></v-text-field>
          </template>
          <template v-else>
            <div class="d-flex">
              <v-select
                class="mr-1"
                :items="types"
                v-model="typeProcedure"
                item-text="label"
                item-value="value"
                outlined
                dense
                label="Type"
              ></v-select>

              <v-select
                :items="subtypes"
                v-model="procedureInterest"
                item-text="name"
                item-value="id"
                outlined
                dense
                label="Procedure"
              ></v-select>
            </div>
          </template>
        </v-col>

        <v-col cols="12" sm="6">
          <template v-if="!edit">
            <v-text-field
              :readonly="!edit"
              v-model="doctorName"
              outlined
              label="Doctor of choice"
              dense
            ></v-text-field>
          </template>
          <template v-else>
            <v-select
              :loading="loading"
              :items="doctors"
              v-model="patient.medicalHistory.doctorChoiceUuid"
              :readonly="!edit"
              item-text="fullname"
              item-value="uuid"
              outlined
              label="Doctor of choice"
              dense
            ></v-select>
          </template>
        </v-col>
      </v-row>
      <v-divider class="texts mx-4"></v-divider>

      <v-row align="center" justify="center" class="mx-4">
        <v-col cols="12" sm="6">
          <v-textarea
            :readonly="!edit"
            auto-grow
            rows="1"
            row-height="65"
            outlined
            v-model="patient.medicalHistory.medicalCondition"
            dense
            label="Medical Condition"
          ></v-textarea>
        </v-col>

        <v-col cols="12" sm="6">
          <v-textarea
            :readonly="!edit"
            auto-grow
            rows="1"
            row-height="65"
            outlined
            v-model="patient.medicalHistory.alergies"
            dense
            label="Alergies"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-divider class="my-1 texts mx-4"></v-divider>

      <v-row align="center" justify="center" class="mx-4">
        <v-col cols="12" sm="6">
          <v-textarea
            :readonly="!edit"
            auto-grow
            rows="1"
            row-height="65"
            outlined
            v-model="patient.medicalHistory.previosSurgery"
            dense
            label="Previous surgeries"
          ></v-textarea>
        </v-col>

        <v-col cols="12" sm="6">
          <v-textarea
            :readonly="!edit"
            auto-grow
            rows="1"
            row-height="65"
            outlined
            v-model="patient.medicalHistory.currentMedications"
            dense
            label="Current medications"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-divider class="my-1 texts mx-4"></v-divider>
      <v-row justify="start" class="mx-4">
        <v-col cols="12" sm="4">
          <v-switch
            :readonly="!edit"
            v-model="patient.medicalHistory.smoking"
            inset
            label="Smoking"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="4">
          <v-switch
            :readonly="!edit"
            v-model="patient.medicalHistory.alcohol"
            inset
            label="Alcohol"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="4">
          <v-switch
            :readonly="!edit"
            v-model="otherSubstances"
            inset
            label="Other Substances"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row justify="start" class="mx-4 texts2">
        <v-col cols="12" sm="12">
          <template v-if="otherSubstances">
            <v-textarea
              auto-grow
              rows="1"
              row-height="65"
              outlined
              v-model="patient.medicalHistory.otherSubstances"
              dense
              label="Substances"
            ></v-textarea>
          </template>
        </v-col>
      </v-row>
      <v-row justify="start" class="mx-4 texts2">
        <v-col cols="12" sm="12">
          <v-textarea
            :readonly="!edit"
            auto-grow
            rows="1"
            row-height="65"
            outlined
            v-model="patient.medicalHistory.details"
            dense
            label="Notes"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-dialog
        v-model="dialogDiscard"
        persistent
        :overlay="false"
        max-width="230px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title primary-title class="text-center">
            Discard Changes
          </v-card-title>
          <v-card-text class="text-center">
            <h4>Are you sure?</h4>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogDiscard = false">cancel</v-btn>
            <v-btn text @click="cancelData" color="error">confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { getAPI } from "@/api/axios-base";
import { notifyError, notifySuccess } from "@/components/Notification";
export default {
  name: "medical-history",
  components: { MaDatePicker },
  data() {
    return {
      patientRes: null,
      patient: {
        weight: 0,
        height: 0,
        heightInches: 0,
        birthDate: "",
        medicalHistory: {
          surgeryTypeId: null,
          treatmentTypeId: null,
          doctorChoiceUuid: null,
          smoking: false,
          alcohol: false,
          otherSubstances: "",
          medicalCondition: "",
          alergies: "",
          previosSurgery: "",
          currentMedications: "",
          details: "",
        },
      },
      otherSubstances: false,
      edit: false,
      loadingedit: false,
      dialogDiscard: false,
      typeProcedure: "Surgery",
      procedureInterest: null,
      doctorChoice: null,
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["treatments", "surgerys"]),
    ...mapState("crmEmployeeModule", ["doctors", "loading"]),
    procedureName() {
      if (this.patientRes == null) {
        return "";
      }
      if (this.patientRes.medicalHistory == null) {
        return "";
      }
      if (
        this.patientRes.medicalHistory.surgeryType == null &&
        this.patientRes.medicalHistory.treatmentType == null
      ) {
        return "";
      }
      if (this.patientRes.medicalHistory.surgeryType != null) {
        return this.patientRes.medicalHistory.surgeryType.name;
      }
      if (this.patientRes.medicalHistory.treatmentType != null) {
        return this.patientRes.medicalHistory.treatmentType.name;
      }
    },
    doctorName() {
      if (this.patientRes == null) {
        return "";
      }
      if (this.patientRes == undefined || this.patientRes == null) {
        return "";
      }
      if (
        this.patientRes.medicalHistory == undefined ||
        this.patientRes.medicalHistory == null
      ) {
        return "";
      }
      if (this.patientRes.medicalHistory.doctorChoice == null) {
        return "";
      }

      return this.patientRes.medicalHistory.doctorChoice.fullname;
    },

    types() {
      return [
        { label: "Surgery", value: "Surgery" },
        { label: "Treatment", value: "Treatment" },
      ];
    },
    subtypes() {
      switch (this.typeProcedure) {
        case "Treatment":
          return this.treatments;
        default:
          return this.surgerys;
      }
    },
    bmi() {
      if (this.patient.weight == 0 && this.patient.height == 0) return 0;

      const inchtometer = this.patient.heightInches * 0.0254;
      const feettometer = this.patient.height * 0.3048;
      const bmi =
        (Number(this.patient.weight) * 0.453592) /
        Math.pow(feettometer + inchtometer, 2);

      return Number(bmi).toFixed(2);
    },

    typebmi() {
      const bmitemp = Number(this.bmi);

      if (bmitemp == 0) {
        return "No BMI";
      }
      if (bmitemp <= 18.5) {
        return "Underweight";
      }
      if (bmitemp > 18.5 && bmitemp <= 24.9) {
        return "Normal weight";
      }
      if (bmitemp >= 25 && bmitemp <= 29.9) {
        return "Overweight";
      }

      return "Obesity";
    },
    colorWeight() {
      if (this.typebmi == "No BMI") {
        return "grey";
      }
      if (this.typebmi == "Underweight") {
        return "amber";
      }
      if (this.typebmi == "Normal weight") {
        return "green";
      }
      if (this.typebmi == "Overweight") {
        return "orange";
      }
      if (this.typebmi == "Obesity") {
        return "red";
      }
    },
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetPatientDoctors"]),
    toEdit() {
      this.edit = true;
      if (
        this.patientRes.medicalHistory &&
        this.patientRes.medicalHistory.surgeryType
      ) {
        this.typeProcedure = "Surgery";
        this.procedureInterest = this.patientRes.medicalHistory.surgeryType.id;
      } else if (
        this.patientRes.medicalHistory &&
        this.patientRes.medicalHistory.treatmentType
      ) {
        this.typeProcedure = "Treatment";
        this.procedureInterest =
          this.patientRes.medicalHistory.treatmentType.id;
      } else {
        this.typeProcedure = "Surgery";
        this.procedureInterest = null;
      }
    },
    async patientData() {
      this.patientRes = (
        await getAPI(`/patient/getPatient/${this.$route.params.uuid}`)
      ).data;

      this.patient = {
        weight: this.patientRes.weight,
        height: this.patientRes.height,
        heightInches: this.patientRes.heightInches,
        birthDate: this.patientRes.birthDate,
        medicalHistory:
          this.patientRes.medicalHistory != null
            ? {
                surgeryTypeId: null,
                treatmentTypeId: null,
                doctorChoiceUuid: this.patientRes.medicalHistory.doctorChoice
                  ? this.patientRes.medicalHistory.doctorChoice.uuid
                  : null,
                smoking: this.patientRes.medicalHistory.smoking,
                alcohol: this.patientRes.medicalHistory.alcohol,
                otherSubstances: this.patientRes.medicalHistory.otherSubstances,
                medicalCondition:
                  this.patientRes.medicalHistory.medicalCondition,
                alergies: this.patientRes.medicalHistory.alergies,
                previosSurgery: this.patientRes.medicalHistory.previosSurgery,
                currentMedications:
                  this.patientRes.medicalHistory.currentMedications,
                details: this.patientRes.medicalHistory.details,
              }
            : {
                surgeryTypeId: null,
                treatmentTypeId: null,
                doctorChoiceUuid: null,
                smoking: false,
                alcohol: false,
                otherSubstances: "",
                medicalCondition: "",
                alergies: "",
                previosSurgery: "",
                currentMedications: "",
                details: "",
              },
      };

      if (
        this.patient.medicalHistory != null &&
        this.patient.medicalHistory.otherSubstances
      ) {
        this.otherSubstances = true;
      }
    },

    saveData() {
      const body = Object.assign({}, this.patient);
      this.loadingedit = true;
      if (this.typeProcedure == "Surgery") {
        body.medicalHistory.surgeryTypeId = this.procedureInterest
          ? this.procedureInterest
          : null;
        body.medicalHistory.treatmentTypeId = null;
      } else {
        body.medicalHistory.treatmentTypeId = this.procedureInterest
          ? this.procedureInterest
          : null;
        body.medicalHistory.surgeryTypeId = null;
      }
      body.weight = Number(body.weight);
      body.height = Number(body.height);
      body.heightInches = Number(body.heightInches);
      if (!this.otherSubstances) {
        body.medicalHistory.otherSubstances = "";
      }
      getAPI
        .put(`/patient/updatePatient/${this.$route.params.uuid}`, body)
        .then(() => {
          notifySuccess(this.$t("medicForm.notificationPatientUpdated"));
          this.cancelData();
        })
        .catch((error) => {
          this.loadingedit = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    discardChanges() {
      this.dialogDiscard = true;
    },
    cancelData() {
      this.patientData();
      this.edit = false;
      this.dialogDiscard = false;
      this.typeProcedure = "Surgery";
      this.procedureInterest = null;
      this.doctorChoice = null;
      this.loadingedit = false;
    },
  },
  mounted() {
    this.patientData();
    this.actGetPatientDoctors();
  },
};
</script>
<style lang="scss" scoped>
.texts {
  margin-top: -26px !important;
  margin-bottom: 10px !important;
}
.texts2 {
  margin-top: -30px !important;
  margin-bottom: 8px !important;
}
</style>
<i18n>
{
  "en": {
    "title": "Edit patient data",
    "Medical history": "Medical history"
  },
  "es": {
    "title": "Editar datos del paciente",
    "Medical history": "Historial médico"
  }
}
</i18n>
