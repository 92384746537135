






























































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { getAPI } from "@/api/axios-base";
import { isDevelop } from "@/enviorment";
import BtnUpdateHelftCare from "./buttons/BtnUpdateHelftCare.vue";
/* import BtnCreateProcedure from "./buttons/BtnCreateProcedure.vue"; */
import BtnProcedures from "./buttons/BtnProcedures.vue";
import BtnCreateQuote from "@/views/Patients/buttons/BtnCreateQuote.vue";
import BtnMakeCall from "@/views/CallCenter/buttons/BtnMakeCall.vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import DrawerSms from "@/views/SMS/components/DrawerSms.vue";
import { notifyError } from "@/components/Notification";
import AccessoriesSales from "@/views/Patients/AccessoriesSales.vue";
import SendEmail from "@/components/emails/SendEmail.vue";
import PatientLabs from "@/views/Patients/PatientLabs.vue";
import PatientMedicalHistory from "@/views/Patients/PatientMedicalHistory.vue";
const BtnAddAppointment = () =>
  import(
    /* webpackChunkName: "add-appointment" */ "@/views/Appointments/buttons/BtnAddAppointment.vue"
  );
const BtnRemovePatient = () =>
  import(
    /* webpackChunkName: "remove-patient" */ "./buttons/BtnRemovePatient.vue"
  );
const Notes = () => import(/* webpackChunkName: "notes" */ "./Notes/Notes.vue");
const CheckList = () =>
  import(/* webpackChunkName: "check-list" */ "./CheckList.vue");
const Payments = () =>
  import(/* webpackChunkName: "payments" */ "./Payments.vue");
const Quotes = () => import(/* webpackChunkName: "quotes" */ "./Quotes.vue");
const PatientPersonalDetails = () =>
  import(
    /* webpackChunkName: "patient-personal-details" */ "./PatientPersonalDetails.vue"
  );
const DocumentSigning = () =>
  import(/* webpackChunkName: "document-signing" */ "./DocumentSigning.vue");
const BtnProcedureStatus = () =>
  import(
    /* webpackChunkName: "procedure-status" */ "@/views/Patients/buttons/BtnProcedureStatus.vue"
  );

export default Vue.extend({
  name: "patient-details",
  components: {
    CheckList,
    Notes,
    Quotes,
    DrawerSms,
    Payments,
    PatientPersonalDetails,
    BtnRemovePatient,
    DocumentSigning,
    // BtnResetSteps,
    BtnCreateQuote,
    BtnMakeCall,
    BtnUpdateHelftCare,
    /*  BtnCreateProcedure, */
    BtnProcedures,
    BtnAddAppointment,
    BtnProcedureStatus,
    AccessoriesSales,
    SendEmail,
    PatientLabs,
    PatientMedicalHistory,
    MaDatePicker,
  },
  data() {
    return {
      details: null,
      loadingEditPro: false,
      dialogEditProcedure: false,
      surgicalDate: null,
      // The procedure selected

      dialogConfirmWhy: false,
      reasonswhy: "",
      loadingwhy: false,

      loading: false,
      tabSelected: 0,
      dialogSignStep1: false,
      dialogDocument: false,
      isDevelop: isDevelop,
      procedureName: "",
      procedureType: "",
      procedureCreatedAt: "",
      procedureData: null,
      sms: {
        number: "",
        message: "",
        // replyTo: "+17869461441",
      },

      dialogImgShow: false,
      image: "",
      width: 800,
    };
  },
  computed: {
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "getRequireNote",
      "getpatientN",
      "getviewLabs",
      "getviewMHistory",
      "gettabPatientDetails",
    ]),
    ...mapState("crmDashboardModule", ["procedureSelectid"]),
    ...mapState("crmMedicFormModule", ["procedure", "patientDetail"]),
    ...mapState("crmSMSModule", ["messagesPatient"]),
    procedures(): any[] {
      return ((this as any).details.proceduresEntity as any[]).map(
        (procedure) => {
          let payments = 0.0;
          const paids = procedure.payments;
          paids.forEach((pay: any) => {
            const p = pay.status == "CONFIRMED" ? parseFloat(pay.amount) : 0.0;
            payments = payments + p;
          });
          const total = procedure.totalTreatment;
          /* .reduce(
            (total: number, item: { amount: string }) => {
              return total + parseFloat(item.amount);
            },
            0
          ); */
          const date = moment(procedure.createAt).format("MM-DD-YYYY");
          const status = procedure.activeStatus;
          let doctor = "Not defined";

          if (procedure.doctor != null) {
            doctor = procedure.doctor.fullname;
          }

          if (
            procedure.treatmentType &&
            procedure.treatmentType != null &&
            procedure.treatmentType !== undefined
          ) {
            return {
              name: procedure.treatmentType.name,
              id: procedure.id,
              type: procedure.procedure_type,
              surgicalDate: procedure.surgicalDate,
              payments: procedure.payments,
              date: date,
              total: total,
              deb: total - payments,
              dasboardStatus: procedure.dasboardStatus,
              doctor: doctor,
              status: status,
            };
          }

          // let deposit = 0;

          if (
            procedure.surgeryType &&
            procedure.surgeryType != null &&
            procedure.surgeryType !== undefined
          ) {
            return {
              name: procedure.surgeryType.name,
              id: procedure.id,
              type: procedure.procedure_type,
              date: date,
              total: total,
              surgicalDate: procedure.surgicalDate,
              deb: total - payments,
              dasboardStatus: procedure.dasboardStatus,
              doctor: doctor,
              status: status,
            };
          }
        }
      );
    },
    /*  tabs1(): any {
      if (
        (this as any).details !== undefined &&
        (this as any).details !== null &&
        (this as any).procedure !== undefined &&
        (this as any).procedure !== null &&
        (this as any).details.proceduresEntity !== undefined &&
        (this as any).details.proceduresEntity !== null &&
        (this as any).details.proceduresEntity.length > 0 &&
        !(this as any).getviewMHistory
      ) {
        let tabs = [
          {
            title: "Check list",
            icon: "mdi-check-all",
            href: "#checklist",
          },

          {
            title: "Details",
            icon: "mdi-card-account-details",
            href: "#details",
          },
          {
            title: "Labs",
            icon: "mdi-biohazard",
            href: "#labs",
          },
          {
            title: "Quotes",
            icon: "mdi-clipboard-text",
            href: "#quotes",
          },
          {
            title: "Accessories",
            icon: "mdi-cash-usd",
            href: "#accessories",
          },
          {
            title: "Payments",
            icon: "mdi-cash-usd",
            href: "#payments",
          },
          {
            title: "Notes",
            icon: "mdi-note-text",
            href: "#notes",
          },
          {
            title: "Document Signing",
            icon: "mdi-draw",
            href: "#documents",
          },
        ];

        if (this.procedureType == "Treatment") {
          tabs = tabs.slice(0, -1);
        }
        if (!this.getviewLabs) {
          tabs = tabs.filter((t) => t.title != "Labs");
        }
        if (!this.getviewMHistory) {
          tabs = tabs.filter((t) => t.title != "Medical History");
        }

        return tabs;
      }
      if (
        (this as any).details !== undefined &&
        (this as any).details !== null &&
        (this as any).procedure !== undefined &&
        (this as any).procedure !== null &&
        (this as any).details.proceduresEntity !== undefined &&
        (this as any).details.proceduresEntity !== null &&
        (this as any).details.proceduresEntity.length > 0 &&
        (this as any).getviewMHistory
      ) {
        let tabs = [
          {
            title: "Details",
            icon: "mdi-card-account-details",
            href: "#details",
          },
          {
            title: "Medical History",
            icon: "mdi-clipboard-text",
            href: "#mhistory",
          },
          {
            title: "Labs",
            icon: "mdi-biohazard",
            href: "#labs",
          },
          {
            title: "Quotes",
            icon: "mdi-clipboard-text",
            href: "#quotes",
          },
          {
            title: "Accessories",
            icon: "mdi-cash-usd",
            href: "#accessories",
          },
          {
            title: "Payments",
            icon: "mdi-cash-usd",
            href: "#payments",
          },
          {
            title: "Notes",
            icon: "mdi-note-text",
            href: "#notes",
          },
          {
            title: "Document Signing",
            icon: "mdi-draw",
            href: "#documents",
          },
        ];

        if (this.procedureType == "Treatment") {
          tabs = tabs.slice(0, -1);
        }
        if (!this.getviewLabs) {
          tabs = tabs.filter((t) => t.title != "Labs");
        }
        if (!this.getviewMHistory) {
          tabs = tabs.filter((t) => t.title != "Medical History");
        }

        return tabs;
      }

      if (
        this.details !== null &&
        this.procedure == null &&
        (this as any).getviewMHistory
      ) {
        let tabs = [
          {
            title: "Details",
            icon: "mdi-card-account-details",
            href: "#details",
          },
          {
            title: "Medical History",
            icon: "mdi-clipboard-text",
            href: "#mhistory",
          },
          {
            title: "Labs",
            icon: "mdi-biohazard",
            href: "#labs",
          },
          {
            title: "Quotes",
            icon: "mdi-clipboard-text",
            href: "#quotes",
          },
          {
            title: "Accessories",
            icon: "mdi-cash-usd",
            href: "#accessories",
          },
          {
            title: "Notes",
            icon: "mdi-note-text",
            href: "#notes",
          },
        ];

        if (!this.getviewLabs) {
          tabs = tabs.filter((t) => t.title != "Labs");
        }
        if (!this.getviewMHistory) {
          tabs = tabs.filter((t) => t.title != "Medical History");
        }
        return tabs;
      }
      if (
        this.details !== null &&
        this.procedure == null &&
        !(this as any).getviewMHistory
      ) {
        let tabs = [
          {
            title: "Details",
            icon: "mdi-card-account-details",
            href: "#details",
          },
          {
            title: "Labs",
            icon: "mdi-biohazard",
            href: "#labs",
          },
          {
            title: "Quotes",
            icon: "mdi-clipboard-text",
            href: "#quotes",
          },
          {
            title: "Accessories",
            icon: "mdi-cash-usd",
            href: "#accessories",
          },
          {
            title: "Notes",
            icon: "mdi-note-text",
            href: "#notes",
          },
        ];

        if (!this.getviewLabs) {
          tabs = tabs.filter((t) => t.title != "Labs");
        }
        if (!this.getviewMHistory) {
          tabs = tabs.filter((t) => t.title != "Medical History");
        }
        return tabs;
      }
      if (
        this.details !== null &&
        (this as any).details.quotes.length != 0 &&
        !(this as any).getviewMHistory
      ) {
        let tabs = [
          {
            title: "Details",
            icon: "mdi-card-account-details",
            href: "#details",
          },
          {
            title: "Labs",
            icon: "mdi-biohazard",
            href: "#labs",
          },
          {
            title: "Notes",
            icon: "mdi-note-text",
            href: "#notes",
          },
        ];

        if (!this.getviewLabs) {
          tabs = tabs.filter((t) => t.title != "Labs");
        }
        if (!this.getviewMHistory) {
          tabs = tabs.filter((t) => t.title != "Medical History");
        }
        return tabs;
      }

      return undefined;
    }, */

    tabs(): any {
      let tabs = (this as any).gettabPatientDetails;
      if (
        (this as any).details !== undefined &&
        (this as any).details !== null &&
        (this as any).procedure !== undefined &&
        (this as any).procedure !== null
      ) {
        if (!this.getviewLabs) {
          tabs = tabs.filter((t: any) => t.title != "Labs");
        }
        if (!this.getviewMHistory) {
          tabs = tabs.filter((t: any) => t.title != "Medical History");
        } else {
          tabs = tabs.filter((t: any) => t.title != "Check list");
        }
        if ((this as any).details.quotes.length == 0) {
          tabs = tabs.filter((t: any) => t.title != "Quotes");
        }

        if (this.procedureType == "Treatment") {
          tabs = tabs.filter((t: any) => t.title != "Document Signing");
        }
      } else {
        if (!this.getviewLabs) {
          tabs = tabs.filter((t: any) => t.title != "Labs");
        }
        if (!this.getviewMHistory) {
          tabs = tabs.filter((t: any) => t.title != "Medical History");
        }
        tabs = tabs.filter(
          (t: any) =>
            t.title != "Check list" &&
            t.title != "Payments" &&
            t.title != "Document Signing" &&
            t.title != "Quotes"
        );
      }

      return tabs;
    },
    typeProc() {
      if (this.procedure == null) {
        return "-";
      }
      if ((this as any).procedure.treatmentType != null) {
        return "Treatment";
      }
      return "Surgery";
    },
  },
  async mounted() {
    if (this.isSuper || this.isAdmin || !this.getRequireNote) {
      await this.getPatientData();
    } else {
      this.dialogConfirmWhy = true;
    }
  },
  destroyed() {
    this.mutprocedureSelectid(0);
    this.mutProcedure(null);
  },
  methods: {
    ...mapMutations("crmDashboardModule", ["mutprocedureSelectid"]),
    ...mapMutations("crmMedicFormModule", [
      "mutPatientDetails",
      "mutProcedure",
      "mutPatientQuotes",
    ]),
    ...mapMutations("crmSMSModule", ["mutSetContact"]),
    ...mapActions("crmSMSModule", ["actMessagesPerNumber", "actSendMessage"]),

    prettyDate(date: any) {
      return moment(date).utc().format("D MMMM YYYY");
    },

    async _goToPatient() {
      try {
        (this as any).loadingwhy = true;
        const patient = (
          await getAPI.post("/patient/getPatientWithNotes", {
            patientUuid: this.$route.params.uuid,
            note: (this as any).reasonswhy,
          })
        ).data;
        this.loadingwhy = false;
        this.reasonswhy = "";
        this.dialogConfirmWhy = false;
        this.mutPatientDetails(patient);
        this.mutPatientQuotes(patient);
        this.loading = false;
        this.details = patient;
        // Si ya había un procedimiento seleccionado se actualiza con éste
        if (this.procedure == null && this.procedureSelectid != 0) {
          await this.getProcedureData(this.procedureSelectid);
        } else {
          if (this.procedure == null) {
            return;
          }

          await this.getProcedureData(
            this.procedure != null ? this.procedure.id : 0
          );
        }
      } catch (error: any) {
        this.loadingwhy = false;
        let mess = error.response.data.message;

        if (mess.includes("[")) {
          mess = mess.replace("[", "");
          mess = mess.replace("]", "");
        }
        notifyError(error.response.data, `An error occurred: ${mess}`);
      }
    },
    cancelEntry() {
      this.dialogConfirmWhy = false;
      this.reasonswhy = "";
      this.$router.push("/patients");
    },

    async getPatientData(): Promise<any> {
      this.loading = true;

      const patient = (
        await getAPI(`/patient/getPatient/${this.$route.params.uuid}`)
      ).data;

      this.mutPatientDetails(patient);
      this.mutPatientQuotes(patient.quotes);
      this.loading = false;
      this.details = patient;
      // Si ya había un procedimiento seleccionado se actualiza con éste
      if (this.procedure == null && this.procedureSelectid != 0) {
        await this.getProcedureData(this.procedureSelectid);
      } else {
        if (this.procedure == null) {
          return;
        }

        await this.getProcedureData(
          this.procedure != null ? this.procedure.id : 0
        );
      }
    },
    async getProcedureData(procedureId: number) {
      this.loading = true;
      const pi = this.procedure != null ? this.procedure.id : procedureId;

      const procedure = this.procedures.find((item) => item.id == pi);

      if (procedure.status != "CANCELED") {
        this.procedureData = procedure != undefined ? procedure : null;
      }
      const responseData = (await getAPI(`/patient/getProcedure/${pi}`)).data;

      if (procedure.status != "CANCELED") {
        this.procedureType =
          procedure != undefined ? procedure.type : "Treatment";
      }
      if (responseData.activeStatus != "CANCELED") {
        this.mutProcedure(responseData);
      } else {
        this.mutProcedure(null);
      }

      this.loading = false;
    },

    cancelEditProc() {
      this.dialogEditProcedure = false;
      this.surgicalDate = null;
      this.loadingEditPro = false;
    },
    confirmEditProc() {
      this.loadingEditPro = true;
      const date = moment(this.surgicalDate).utc().toISOString();
      getAPI
        .post("/sprocedures/updateProcedure/" + this.procedure.id, {
          surgicalDate: date,
        })
        .then(() => {
          this.getPatientData();
          this.loadingEditPro = false;
          this.dialogEditProcedure = false;
          this.surgicalDate = null;
        })
        .catch((error) => {
          this.loadingEditPro = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
});
